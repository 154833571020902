<template>
  <div>
    <div id="tender-page">
      <h1 class="h1-title">Tenders</h1>
      <div v-if="!currentDealership.is_tender_account" class="flex-row">
        <div class="flex-column horizontal-flex-center margin-bottom-4 margin-top-4">
          <img class="splash-img horizontal-flex-center" src="/assets/img/splash1.jpg" />
          <h2 class="h2-title margin-top-1-05 horizontal-flex-center">Welcome To Tenders</h2>
          <p class="body-1 margin-top-05">
            You don't seem to be setup with the iAppraise iTender system. Contact Us to learn more.
          </p>
        </div>
      </div>
      <div v-if="currentDealership.is_tender_account">
        <div class="flex-row">
          <secondary-button
            class="margin-right-05"
            title="Start tender from CSV"
            icon="/assets/img/icon-plus.svg"
            v-on:click="tenderModalVisible = true"
          />
          <search-bar
            class="margin-right flex-grow"
            availableFilterMode="tenders"
            placeholder="Search for name, phone number or email"
            :emitActions="true"
            @applyFilter="applyFilter"
          />
        </div>
        <filter-bar :customFilters="filters" @removeFilter="removeFilter" />

        <div class="results-table-container margin-top">
          <table class="results-table">
            <tr class="header">
              <th>Title</th>
              <th>Number of Appraisals</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Tender Status</th>
            </tr>
            <tr
              v-on:click="goToTender(tender)"
              class="data-row selectable"
              v-for="tender in filteredTenders()"
              v-bind:key="tender.id"
            >
              <td>{{ tender.title ? tender.title : "-" }}</td>
              <td>{{ tender.form_count }}</td>
              <td>{{ formatDate(tender.start_time) }}</td>
              <td>{{ formatDate(tender.end_time) }}</td>
              <td v-if="tender">
                <div class="flex-row">
                  <div :title="tenderTitle(tender)" :class="indicatorClassForTender(tender)"></div>
                  <p class="margin-left-025">{{ tenderTitle(tender) }}</p>
                </div>
              </td>
            </tr>
          </table>
          <paginator-bar
            v-model:page="page"
            :pageBounds="pageBounds()"
            :loadedItemsCount="tenders.length"
            :loading="loadingTenders"
            :hasMorePages="nextUrl != null"
            :totalItemsCount="tenderCount"
            @loadMore="getNextTenders()"
          />
        </div>
      </div>
      <create-tender-modal
        v-if="tenderModalVisible"
        :forCSVUpload="true"
        @cancel="tenderModalVisible = false"
        @submit="tenderCSV"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { fetchTenders, createTenderFromCSV } from "../../../api/Tender.js";
import { axiosInstance } from "../../../api/axiosHandler";
import { tenderHasEnded, tenderHasStarted } from "../../../helpers/v2/formHelpers.js";
import { mapActions, mapState } from "vuex";
import CreateTenderModal from "../../../components/Modals/v2/CreateTenderModal.vue";
import { pageBounds } from "../../../helpers/v2/utilityHelpers";

import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../../components/Views/v2/FilterBar.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import PaginatorBar from "../../../components/Common/PaginatorBar.vue";

import { indicatorClassForTender } from "../../../helpers/v2/resultsTableHelpers";
import _ from "lodash";

export default {
  name: "Tenders",
  components: { SearchBar, FilterBar, PaginatorBar, SecondaryButton, CreateTenderModal },
  data: function () {
    return {
      searchDict: {
        title: null,
        end_time_gt: null,
        end_time_lt: null
      },
      filters: {},
      axiosCancelToken: null,
      nextUrl: null,
      loadingTenders: false,
      tenderModalVisible: false,
      loadingTenderModal: false,
      tenders: [],
      tenderCount: 0,
      page: 1
    };
  },
  methods: {
    fetchTenders: function () {
      if (this.axiosCancelToken) {
        if (this.axiosCancelToken.cancel) {
          this.axiosCancelToken.cancel("New Request");
        }
      }
      this.axiosCancelToken = axios.CancelToken.source();
      fetchTenders(this.searchDict, this.axiosCancelToken)
        .then(response => {
          this.tenderCount = response.count;
          this.nextUrl = response.next;
          this.tenders = response.results;
          this.page = 1;
        })
        .catch(error => {
          if (!error.__CANCEL__) {
            this.addError(error);
          }
        });
    },
    filteredTenders: function () {
      let bounds = this.pageBounds();
      var toReturn = this.tenders;

      if ("tender_status" in this.filters) {
        toReturn = toReturn.filter(tender => {
          switch (this.filters["tender_status"]) {
            case 1:
              return !this.tenderHasStarted(tender);
            case 2:
              return this.tenderHasStarted(tender) && !this.tenderHasEnded(tender);
            case 3:
              return this.tenderHasEnded(tender);
            default:
              return false;
          }
        });
      }
      return toReturn.slice(bounds.lowBound, bounds.highBound);
    },
    pageBounds() {
      return pageBounds(this.page);
    },
    tenderHasEnded(tender) {
      return tenderHasEnded(tender.end_time);
    },
    tenderHasStarted(tender) {
      return tenderHasStarted(tender.start_time);
    },
    getNextTenders() {
      if (this.nextUrl) {
        this.loadingTenders = true;
        axiosInstance()
          .get(this.nextUrl, null)
          .then(response => {
            this.loadingTenders = false;
            this.nextUrl = response.data.next;
            response.data.results.forEach(tender => {
              this.tenders.push(tender);
            });
          })
          .catch(error => {
            this.loadingTenders = false;
            this.addError(error);
          });
      }
    },
    formatDate(dateString) {
      let convertedDate = new Date(dateString);
      return convertedDate.toDateString() + ", " + convertedDate.toLocaleTimeString();
    },
    tenderTitle(tender) {
      if (tenderHasEnded(tender.end_time)) {
        return "Tender Has Ended";
      } else if (tenderHasStarted(tender.start_time)) {
        return "Tender Has Started";
      } else {
        return "Tender Hasn't Started Yet";
      }
    },
    indicatorClassForTender(tender) {
      return indicatorClassForTender(tender.start_time, tender.end_time);
    },
    goToTender: function (tender) {
      this.$router.push({ name: "Tender", params: { id: tender.id } });
    },
    tenderCSV: function (tenderDict) {
      if (this.loadingTenderModal) {
        return;
      }

      this.loadingTenderModal = true;
      createTenderFromCSV(tenderDict)
        .then(response => {
          this.tenders.unshift(response);
          this.tenderModalVisible = false;
          this.loadingTenderModal = false;
        })
        .catch(error => {
          this.loadingTenderModal = false;
          this.addError(error);
        });
    },
    clearFilters: function () {
      this.searchDict.title = null;
      this.searchDict.end_time_gt = null;
      this.searchDict.end_time_lt = null;
    },
    applyFilter: function (filter) {
      if (filter.filter.key == "quick_search") {
        this.searchDict.title = filter.filter.value;
      } else {
        var newFilters = _.cloneDeep(this.filters);
        newFilters[filter.filter.key] = filter.filter.value;
        this.filters = newFilters;
      }
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  watch: {
    searchDict: {
      handler: function () {
        this.fetchTenders();
      },
      deep: true
    },
    "$store.state.formStore.filters": {
      deep: true,
      handler(newVal) {
        if ("date_time_created_gt" in newVal) {
          this.searchDict.end_time_gt = newVal.date_time_created_gt;
        } else {
          this.searchDict.end_time_gt = null;
        }

        if ("date_time_created_lt" in newVal) {
          this.searchDict.end_time_lt = newVal.date_time_created_lt;
        } else {
          this.searchDict.end_time_lt = null;
        }
      }
    }
  },
  mounted: function () {
    this.fetchTenders();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#tender-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.splash-img {
  width: 326px;
  height: 170px;
  object-fit: cover;
}
</style>
